import {formatDateTime, getDateTimeFormats} from '@/utils/timeFormatter';

const {DATE_FORMAT} = getDateTimeFormats();

export default {
    email: 'Email must be a valid email',
    emailFn: (field: string) => `${field} must be a valid email` as const,
    required: 'Required',
    birthDate: "Date can't be later than today",
    date: 'Invalid Date',
    dateWithFormat: (format: string) => `Invalid date format, please use ${format}` as const,
    time: 'Invalid Time',
    timeWithFormat: (format: string) => `Invalid time format, please use ${format}` as const,
    stringMin: 'Too Short!',
    minCharacters: (value: number) => `Please enter at least ${value} characters` as const,
    maxCharacters: (value: number) => `Should not exceed ${value} characters`,
    minOptions: (value: string) => `Select at least ${value} option(s)` as const,
    stringMax: 'Too Long!',
    number: 'Incorrect value! Should be a number',
    numberMin: 'Incorrect value!',
    numberMax: 'Incorrect value!',
    phoneNumber: 'Phone number is not valid',
    phoneNumberDuplication: 'Such number is already in use.',
    zipCode: 'ZIP code must be a 5 digit value',
    futureDate: "Date can't be before today",
    maxDate: (maxDate: string) => `Date can't be after ${formatDateTime(maxDate, DATE_FORMAT)}` as const,
    minDate: (minDate: string) => `Date can't be before ${formatDateTime(minDate, DATE_FORMAT)}` as const,
    maxTime: (maxTime: string) => `Time can't be after ${maxTime}` as const,
    minTime: (minTime: string) => `Time can't be before ${minTime}` as const,
    minTimeV2: (minTime: string) => `Should be after ${minTime}` as const,
    afterTime: 'Wrong time range',
    wrongDateFormat: 'Wrong date format',
    wrongTime: 'Invalid Time',
    notEmptyIfAdded: 'Cannot be empty if added',
    uniqueName: 'Name should be unique',
    identifier: 'Only A-Z, a-z, 0-9 and "-" are allowed here',
    url: 'Incorrect URL link',
    arrivalTimeInFuture: 'Actual arrival time cannot be more than 20 minutes in the future.',
    cancelEffectiveDate: 'Cancel effective date should be between now and last cancel effective date.',
    integer: 'Value must be an integer',
    numberField: 'Expiration Period must be a number',
    outOfRange: 'Out of valid range',
    invalidId: 'Invalid ID',
    medicalWasteManifestId: 'Must be 9 to 14 digit alphanumeric string',
    dischargeDateBeforeAdmitDate: 'Cannot be earlier than Admit Date',
    alreadyRelatedLogs: 'Logs are already related',
    alreadyDuplicatedLog: 'Log already has a duplicate',
    alreadyDuplicateLogOf: (entryName: string) => `Log is already a duplicate of ${entryName}`,
    alreadyClosedLog: 'Log is already closed',
    notFoundLog: 'Log entry not found',
} as const;
